$font-family-base:'Poppins', sans-serif;
$iceblue:    #3EC1C9;
$liteice: #A2E1E4;
$body-bg: $iceblue;
$litewhite: #F6F6F6;
$darkblue: #364F6B;
$rose: #D74F77;
$grey: #646F7A;


$blue: $darkblue;
$pink: $rose;
// $indigo:  #6610f2 !default;
// $purple:  #6f42c1 !default;
// $pink:    #d63384 !default;
// $red:     #dc3545 !default;
// $orange:  #fd7e14 !default;
// $yellow:  #ffc107 !default;
// $green:   #198754 !default;
// $teal:    #20c997 !default;
// $cyan:    #0dcaf0 !default;

