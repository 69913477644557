#challenge {
    background-color:$litewhite;
    // background-color: $iceblue;
    padding: 1em;
    max-width: 800px;
    margin-top: 2em;
    margin-bottom: 2em;

    h2 {
        color:$grey;
    }
    .form-control {
        background-color: $litewhite;
    }
    .btn-check:focus + .btn-primary, .btn-primary:focus {
        // color: #fff;
        // background-color: #2e435b;
        // border-color: #2b3f56;
        box-shadow: 0 0 0 0.25rem rgba(54, 79, 107, 0.30);
    }
    .btn-primary {
        margin-top: .9em;
    }
    .text-return {
        // border: 1px solid rgba(54, 79, 107, 0.30);
        color: grey;
    }
    button {
        width: 100%;
    }

   span {
        float: left;
        line-height: 35px;
        font-size: 2.5em;
        font-weight: 700;
        color: $rose;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: -5px;
        margin-right:.25em;
      
      }

      .clear {
        margin-top: 1em;
      }
      .quote {
        margin-top: 0.5em;
        color: $rose;
      }
      .code {
        font-family: monospace;
        padding: 0.5em 1em 0;
      }
      small {
        color: $grey;
      }
}

@include media-breakpoint-up(md) {
    #challenge {
        border-radius: 14px; 
    }
  }