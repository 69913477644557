// fonts, variables, bootstrap, custom

//look in bootstrap/scss/_variables.scss
$web-font-path: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap' !default;
@import url($web-font-path);

@import "./_variables.scss";
// Import Bootstrap
@import "bootstrap/scss/bootstrap.scss";

// Import any custom styles
body {
    background-image: url('./../images/bkg-sw.png');
}

.btn-primary:hover {
    color: $darkblue;
    background-color: $liteice;
    border-color: $liteice;
}

.navbar-text {
    margin: 0 .25em;
    padding-bottom: 0;
}
.nav-max {
    max-width: 936px;
}
.navbar-nav {
    text-align: end;
}

.icon {
    display: inline-block;
    text-align: center;
}
.icon-color {
        svg {
          color: $darkblue;
          cursor: pointer;
          transition: 400ms all;
          font-size: 44px;
          margin: .2em .1em;
        }
        svg:hover {
            color: $iceblue;
            transform: scale(1.2);
        }

}


h1, h2, h3 {
    color:$rose;
    font-weight: 700;
    text-align: left;
}

#about {
    background-color:$litewhite;
    padding: 1em;
    max-width: 800px;

    h2 {
        font-weight: 700;
        font-size: 2.0em; 
    }
    p {
        text-align: left;
    }

    p.subtitle{
        width: 100%;
        display: inline-block;
        font-weight: 700;
        color:$grey;
        margin-bottom: 0;
    }
    img {
        width: 273px;
        height: 273px;
        // background: rgba(196, 196, 196, 0.1);
        border: 10px solid $rose;
        border-radius: 153px;
    }
    ul {
        list-style-type: none;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        margin-left:-10px;
        max-width: 276px;
    }
    ul li::before {
        content: "\2022";
        color: $rose;
        font-weight: bolder;
        display: inline-block; 
        width: 1em;
        margin-left: -1em;
        
      }
    li {
        background-color: $gray-300;
        border-radius: 5px;
        padding: 0 5px;
        width: fit-content;
        margin: 0 5px 5px 0;
        padding: 0px 6px;
        color: $body-color;
    }

}

#projects, #tech, #learn {
    background-color:$litewhite;
    padding: 1em;
    max-width: 800px;
    margin-top: 2em;
}

.img-border {
    border: 3px solid $darkblue;
    border-radius: 3px
}

figure span {
    font-size: smaller;
}

#learn {
    ul {
        list-style-type: none;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        margin-left:-10px;
        max-width: 450px;
    }
    ul li::before {
        content: "\2022";
        color: $rose;
        font-weight: bolder;
        display: inline-block; 
        width: 1em;
        margin-left: -1em;
        
      }
    li {
        background-color: $gray-300;
        border-radius: 5px;
        padding: 0 5px;
        width: fit-content;
        margin: 0 5px 5px 0;
        padding: 0px 6px;
        color: $body-color;
    }
}

#footer {
    background-color:$litewhite;
    padding: 1em;
    max-width: 800px;
    margin-top: 2em;

    h2 {
        color:$rose;
    }
}

#links {
    max-width: 800px;
    margin-top: 2em;

    a {
        color:$darkblue;
    }
    a:hover {
        color: $rose;
    }
}


@include media-breakpoint-up(md) {
    #about {
        h2 {
            font-weight: 700;
            font-size: 2.5em;
            text-align: left;
        }
    }
    .icon {
        display: inline-block;
        text-align: right;
    }
    
  }

  @include media-breakpoint-up(md) {
    #about, #learn{
        border-radius: 14px;

        p.subtitle {
            margin-bottom:2em;
        }
    }

    #footer, #tech {
        border-radius: 14px;
    }

    #projects {
        border-radius: 14px;
    }
    #challenge {
        border-radius: 14px; 
    }
  }

  @import "./challenge.scss";